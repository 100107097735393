<script setup lang="ts">
useForm()
</script>

<template>
  <div
    class="grid grid-cols-[repeat(auto-fit,minmax(15.5rem,1fr))] items-start gap-4"
  >
    <Field
      type="zip"
      name="zip"
      rules="required"
      placeholder="Enter ZIP Code"
      class="text-center"
    />
    <ClickToCall v-slot="{ handleClick }">
      <Button
        wide
        class="whitespace-nowrap text-center"
        :to="phone.uri"
        @click="handleClick"
      >
        Call {{ phone.formatted }} <br />
        TTY 711, 24/7
      </Button>
    </ClickToCall>
  </div>
</template>
