<script setup lang="ts">
useForm()
</script>

<template>
  <div class="max-w-xl mx-auto grid gap-4">
    <Select
      name="state"
      initialValue="AL"
      :options="
        states.map((state) => ({ value: state.code, text: state.name }))
      "
    />
    <ClickToCall v-slot="{ handleClick }">
      <Button wide :to="phone.uri" @click="handleClick" class="text-center">
        Call {{ phone.formatted }} <br />
        TTY 711, 24/7
      </Button>
    </ClickToCall>
  </div>
</template>
