<script setup lang="ts">
useHead({
  title: `${brand.name} Ask a Licensed Medicare Advantage Agent`,
})

analytics.page({
  name: 'LP 3.0',
  category: 'LP',
})
</script>

<template>
  <Layout>
    <!-- hero -->
    <div class="bg-darkBlue text-white">
      <div class="container py-16 lg:flex">
        <div class="lg:flex-1 lg:max-w-lg lg:mr-auto">
          <h1 class="text-4xl sm:text-5xl">
            <slot name="title">
              Medicare Plans Made <Underline>Easy</Underline>
            </slot>
          </h1>
          <div class="mt-6 mb-12 text-2xl text-white text-opacity-75">
            <slot name="subtitle">
              Check your eligibility to enroll and compare your Medicare
              Advantage plan options with a licensed insurance agent.
            </slot>
          </div>
          <LandingPageCallMedigapForm />
        </div>
        <img
          src="../assets/landing-page-medigap-hero-2024.png"
          class="w-[460px] -mb-16 mt-16 mx-auto lg:mt-0 lg:ml-16 lg:mr-0 lg:self-end"
        />
      </div>
    </div>

    <!-- states -->
    <div class="container py-16 space-y-12">
      <div class="max-w-3xl mx-auto text-center space-y-6">
        <h2 class="text-4xl sm:text-5xl md:text-6xl">
          Select Your <Underline>State</Underline> Below
        </h2>
        <div class="text-2xl text-gray-500">
          To get a <span class="text-gray-900 font-medium"> FREE </span> no
          obligation Medicare insurance plan quote & possibly save!
        </div>
      </div>
      <LandingPageCallMedigapFormState />
    </div>

    <!-- phone -->
    <div class="bg-darkBlue text-white">
      <div class="container flex flex-col-reverse lg:flex-row xl:max-w-6xl">
        <img
          src="../assets/landing-page-medigap-woman-phone.png"
          class="w-80 lg:w-96 self-center lg:self-end lg:mr-16"
        />
        <div class="flex-1 self-center pt-16 pb-8 lg:pb-16">
          <h2 class="text-4xl sm:text-5xl md:text-6xl">
            Shop over the <Underline>phone</Underline>
          </h2>
          <div class="mt-6 mb-12 text-2xl text-white text-opacity-75">
            Speak with a licensed insurance agent to check your eligibility to
            enroll and get advice on choosing a Medicare Advantage plan that's
            right for you.
          </div>
          <div class="flex items-center space-x-6">
            <ClickToCall v-slot="{ handleClick }: any">
              <a
                :href="phone.uri"
                @click="handleClick"
                class="block p-2 bg-red bg-opacity-30 rounded-full"
              >
                <Icon i-heroicons-solid:phone class="w-8 h-8 text-red" />
              </a>
            </ClickToCall>
            <ClickToCall v-slot="{ handleClick }: any">
              <a :href="phone.uri" @click="handleClick" class="text-3xl">
                {{ phone.formatted }}
              </a>
            </ClickToCall>
          </div>
          <div class="ml-[4.5rem]">
            <span class="text-lg text-white text-opacity-75"> TTY Users: </span>
            <span class="text-3xl"> 711, 24/7 </span>
          </div>
        </div>
      </div>
    </div>

    <!-- faq -->
    <div class="container py-16 space-y-12">
      <h2 class="text-4xl sm:text-5xl md:text-6xl text-center">
        Frequently Asked <Underline>Questions</Underline>
      </h2>
      <div class="grid lg:grid-cols-2 gap-4">
        <div>
          <AccordionPanel
            title="What is Medicare Supplement Insurance (a.k.a. Medigap)"
          >
            Medicare supplement insurance is a type of Medicare insurance plan
            that works with Original Medicare, instead of replacing it. It helps
            pay for some out-of-pocket costs. Original Medicare, which includes
            Parts A and B, covers some of your basic hospital and medical
            expenses, respectively. Unfortunately, some does not mean all. The
            expenses which Medicare does not cover - sometimes referred to as
            "gaps" in coverage - are what Medigap insurance plans are designed
            to protect you from.
          </AccordionPanel>
          <AccordionPanel
            title="Who is eligible for Medicare Supplement Insurance?"
          >
            If any of the following scenarios apply to you, then you may have a
            Guaranteed Issue Right or the ability to enroll in Medicare
            Supplement Insurance.
            <br />
            <br />
            1) You are currently covered under Medicare Parts A & B. 2) You are
            within 6 months of turning 65. 3) You are within 6 months of
            receiving Part B coverage. 4) If you are about to lose your group
            health insurance plan.
            <br />
            <br />
            Please note that you may still be required to answer some medical
            questions about your overall health and wellness. For further
            clarification, or if you have any questions, please do not hesitate
            to contact us at
            <ClickToCall v-slot="{ handleClick }: any">
              <Link :to="phone.uri" @click="handleClick">
                {{ phone.formatted }} </Link
              >,
            </ClickToCall>
            TTY Users: 711. Give us a call today to speak with a licensed
            insurance agent.
          </AccordionPanel>
          <AccordionPanel
            title="When can I sign up for a Medicare Advantage Plan?"
          >
            Initial Enrollment Period - Applies to: Turning 65 - Purpose: Enroll
            in Original Medicare and possibly a Medicare Advantage Plan - When:
            3 months before 65th birthday, birthday month, and 3 months after
            65th birthday month
            <br />
            <br />
            Medicare Annual Election Period - Applies to: Medicare Beneficiaries
            - Purposes: Reevaluate & make changes to current Medicare coverage -
            When: October 15 - December 7; and
            <br />
            <br />
            Special Enrollment Period - Applies to: Aging-in, recently moved,
            lost health insurance coverage or have other Special Enrollment
            Period qualifying conditions - When: Special Enrollment Period
            eligibility varies by qualifying.
            <br />
            <br />
            Enrollment in Medicare/MedicareAdvantage may be limited to certain
            times of the year unless you qualify for a Special Enrollment
            Period.
            <br />
            <br />
            Speak with a licensed insurance agent to review your eligibility and
            possibly enroll in a Medicare Advantage Plan during one of these
            enrollment periods at
            <ClickToCall v-slot="{ handleClick }: any">
              <Link :to="phone.uri" @click="handleClick">
                {{ phone.formatted }} </Link
              >,
            </ClickToCall>
            TTY Users: 711.
          </AccordionPanel>
        </div>
        <div>
          <AccordionPanel title="What is Medicare Advantage">
            Medicare Advantage plans are an alternative way to get your Original
            Medicare benefits and can help limit your Medicare out-of-pocket
            costs. Plans may offer additional benefits. Plans vary, so it's
            important to compare them carefully to find the right option for
            you.
          </AccordionPanel>
          <AccordionPanel title="Who is Medicare Supplement recommended for?">
            Medicare Supplement Insurance plans can be a great choice for some
            people. Assuming you're eligible to enroll, the choice to sign up
            for Medicare Supplement comes down to your individual needs. Many
            people can benefit from a Medicare Supplement insurance policy,
            which offers the same network of providers as Original Medicare.
            Original Medicare provides standard coverage to every beneficiary,
            regardless of income. What it does not provide, however, is 100%
            coverage of your medical costs. Whether you have trouble covering a
            surprise medical bill, or you want to protect your nest egg in
            retirement, this additional coverage may be the right choice for
            you.
          </AccordionPanel>
          <AccordionPanel title="Where do I enroll in a Medicare plan?">
            One way to get a complete view of your plan options is to talk to an
            licensed insurance agent that can give you quotes from several
            insurance companies and plan types all on one call. This helps save
            you having to put your information onto websites that may sell your
            information, or going from insurance company to insurance company
            trying to find the right plan options available for you.
            <br />
            <br />
            At {{ brand.name }}, this is our specialty. We make comparing plan
            options easy.
          </AccordionPanel>
          <AccordionPanel title="Important: How to save on your Medigap plan">
            The ten types of Medicare Supplement Insurance Plans (like F, G & N)
            have standard benefits defined by Medicare. Each Medicare Supplement
            insurance plan of a given type (letter) offers the same benefits
            regardless of which insurance company you choose. This means
            shopping around isn't just about finding the right plan for you,
            it's about finding an affordable rate that may stay low over time.
          </AccordionPanel>
        </div>
      </div>
    </div>

    <!-- divider -->
    <div class="container">
      <div class="h-px bg-gray-200"></div>
    </div>

    <!-- quote -->
    <div class="container py-16 space-y-12">
      <div class="max-w-3xl mx-auto text-center space-y-6">
        <h2 class="text-4xl sm:text-5xl md:text-6xl">
          Get a <Underline>FREE</Underline> no obligation quote
        </h2>
        <div class="text-2xl text-gray-500">
          Take the first step towards
          <span class="text-gray-900 font-medium">protecting your health.</span>
          Speak with a licensed insurance agent to check your eligibility to
          enroll and discuss your Medicare coverage options.
        </div>
      </div>

      <div class="max-w-2xl mx-auto">
        <LandingPageCallMedigapForm />
      </div>
    </div>

    <!-- divider -->
    <div class="container">
      <div class="h-px bg-gray-200"></div>
    </div>
  </Layout>
</template>
